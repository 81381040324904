import React, { useState, useEffect, useContext } from 'react';
import styles from './DashboardPage.module.css'; // Updated import statement
import Footer from '../components/Footer';
import HeadBar from '../components/HeadBar';
import { MessageContext } from '../context/MessageContext'; // Import MessageContext

const DashboardPage = () => {
    const [loading, setLoading] = useState(true);
    const [storedBots, setStoredBots] = useState([]); 
    const { sendMessage } = useContext(MessageContext); // Use MessageContext

    localStorage.removeItem('botToken');
    localStorage.removeItem('currentConfig');
    localStorage.removeItem('currentBot');
    localStorage.removeItem('botDetails');
    localStorage.removeItem('botData');
    localStorage.removeItem('configs');

    function checkBotCode() {
        if (localStorage.getItem('botCode')) {
            window.location.href = '/create-bot';
        }
    }

    const reloadBotData = async () => {
        const token = JSON.parse(localStorage.getItem('userToken')).token;
        const botsResponse = await fetch('https://ayodev.de:2096/api/bots', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        if (botsResponse.ok) {
            const botsData = await botsResponse.json();
            localStorage.setItem('botsData', JSON.stringify(botsData));
            setStoredBots(botsData.botInfo);
        } else {
            if (botsResponse.status === 403) {
                localStorage.clear();
                window.location.href = '/login';
            }
            console.error('Failed to fetch bots data from API');
        }
    };

    useEffect(() => {
        const fetchMessages = async () => {
            const token = JSON.parse(localStorage.getItem('userToken')).token;
            const location = '/dashboard';
            const response = await fetch(`https://ayodev.de:2096/api/activeMessages?location=${location}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.ok) {
                const data = await response.json();
                if (data) {
                    const messages = data.messages;
                    messages.forEach(message => {
                        sendMessage(message.message, message.type);
                    });
                }
            } else {
                console.error('Failed to fetch messages from API');
            }
        };

        fetchMessages();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const urlParams = new URLSearchParams(window.location.search);
            const code = urlParams.get('code');

            if (code) {
                try {
                    const response = await fetch('https://ayodev.de:2096/api/verify', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ code })
                    });

                    if (response.ok) {
                        const data = await response.json();
                        localStorage.setItem('userToken', JSON.stringify(data));
                        urlParams.delete('code');
                        window.history.replaceState(null, null, window.location.pathname);

                        const token = data.token;
                        const botsResponse = await fetch('https://ayodev.de:2096/api/bots', {
                            method: 'POST',
                            headers: {
                                'Authorization': `Bearer ${token}`
                            }
                        });

                        if (botsResponse.ok) {
                            const botsData = await botsResponse.json();
                            localStorage.setItem('botsData', JSON.stringify(botsData));
                            setStoredBots(botsData.botInfo);
                        } else {
                            if (response.status === 403) {
                                localStorage.clear();
                                window.location.href = '/login';
                            }
                            console.error('Failed to fetch bots data from API');
                        }
                    } else {
                        console.error('Failed to fetch data from API');
                    }
                } catch (error) {
                    console.error('Error:', error);
                }
            } else if (!localStorage.getItem('userToken') || !localStorage.getItem('botsData')) {
                localStorage.clear();
                window.location.href = '/login';
            }
            checkBotCode();

            setLoading(false);
        };

        if (localStorage.getItem('botsData')) {
            checkBotCode();
            reloadBotData();
            const storedBotsData = JSON.parse(localStorage.getItem('botsData'));
            setStoredBots(storedBotsData.botInfo);
            setLoading(false);
        } else {
            fetchData();
        }
    }, []);

    const handleChooseClick = (botId) => {
        window.location.href = `https://ayodev.de/dashboard/bot?uuid=${botId}`;
    };

    if (loading) {
        return (
            <div className={styles.spinnerContainer}>
                <div className={styles.spinner}></div>  {/* Spinner-Element */}
            </div>
        );
    }

    return (
        <div>
            <HeadBar />
            <div className={styles.dashboardContent}>
                {storedBots.map((bot, index) => (
                    bot.UUID !== 'awaiting_assignment' ? (
                        <div key={index} className={styles.dashboardBotCard}>
                            <img className={styles.dashboardBotBanner} src={bot.banner_url} alt={`${bot.bot_name} banner`} />
                            <div className={styles.dashboardBotInfo}>
                                <div className={styles.dashboardBotInfoTop}>
                                    <img className={styles.dashboardBotAvatar} src={bot.avatar_url} alt={`${bot.bot_name} avatar`} />
                                    <div className={styles.dashboardBotNameId}>
                                        <h2 className={styles.dashboardBotName}>{bot.bot_name}</h2>
                                        <p className={styles.dashboardBotId}>UUID: {bot.UUID}</p>
                                    </div>
                                </div>
                                <p className={styles.dashboardBotDescription}>Server: {bot.guild_id}</p>
                                <div className={styles.dashboardBotButtons}>
                                    <button className={styles.dashboardChooseButton} type="button" onClick={() => handleChooseClick(bot.UUID)}>CHOOSE</button>
                                    <button className={styles.dashboardOptionsButton} type="button">⋮</button>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div key={index} className={`${styles.dashboardBotCard} ${styles.awaitingAssignment}`}>
                            <img className={styles.dashboardBotBanner} src={bot.banner_url || 'https://placehold.co/600x200'} alt="Awaiting Assignment banner" />
                            <div className={styles.dashboardBotInfo}>
                                <div className={styles.dashboardBotInfoTop}>
                                    <img className={styles.dashboardBotAvatar} src={bot.avatar_url || 'https://placehold.co/200x200'} alt="Awaiting Assignment avatar" />
                                    <div className={styles.dashboardBotNameId}>
                                        <h2 className={styles.dashboardBotName}>{bot.bot_name}</h2>
                                    </div>
                                </div>
                                <p className={styles.dashboardBotDescription}>Der Bot wird erstellt gedulde dich bitte ein paar Minuten...</p>
                                <button className={styles.dashboardRefreshButton} type="button" onClick={() => reloadBotData()}>⟳</button>
                            </div>
                        </div>
                    )
                ))}
            </div>
            <Footer />
        </div>
    );
};

export default DashboardPage;