import React, { useState, useEffect } from 'react';
import styles from './MessagesContent.module.css';

const MessagesContent = () => {
    const [message, setMessage] = useState('');
    const [type, setType] = useState('none');
    const [location, setLocation] = useState('/dashboard');
    const [activeMessages, setActiveMessages] = useState([]);

    const fetchActiveMessages = () => {
        fetch('https://ayodev.de:2096/admin/activeMessages', {
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('userToken')).token}`
            }
        })
        .then(response => response.json())
        .then(data => setActiveMessages(data.messages));
    };

    useEffect(() => {
        fetchActiveMessages();
    }, []);

    const handleSendMessage = () => {
        fetch('https://ayodev.de:2096/admin/newMessage', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('userToken')).token}`
            },
            body: JSON.stringify({ message, type, location })
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                setMessage('');
                setType('none');
                setLocation('/dashboard');
                fetchActiveMessages();
            }
        });
    };

    const handleRemoveMessage = (messageId) => {
        fetch('https://ayodev.de:2096/admin/removeMessage', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('userToken')).token}`
            },
            body: JSON.stringify({ messageId })
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                setActiveMessages(activeMessages.filter(msg => msg.id !== messageId));
            }
        });
    };

    return (
        <div className={styles.messagesContent}>
            <h1 className={styles.centered}>Send Message to Users</h1>
            <div className={styles.messageBox}>
                <textarea
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Enter your message here"
                    className={styles.textarea}
                />
            </div>
            <div className={styles.messageInfoBoxesRow}>
                <div className={styles.messageInfoBox}>
                    <label>Type:</label>
                    <div className={styles.messageRadioGroup}>
                        <label>
                            <input
                                type="radio"
                                value="none"
                                checked={type === 'none'}
                                onChange={(e) => setType(e.target.value)}
                            />
                            None
                        </label>
                        <label>
                            <input
                                type="radio"
                                value="message"
                                checked={type === 'message'}
                                onChange={(e) => setType(e.target.value)}
                            />
                            Message
                        </label>
                        <label>
                            <input
                                type="radio"
                                value="warning"
                                checked={type === 'warning'}
                                onChange={(e) => setType(e.target.value)}
                            />
                            Warning
                        </label>
                        <label>
                            <input
                                type="radio"
                                value="error"
                                checked={type === 'error'}
                                onChange={(e) => setType(e.target.value)}
                            />
                            Error
                        </label>
                        <label>
                            <input
                                type="radio"
                                value="success"
                                checked={type === 'success'}
                                onChange={(e) => setType(e.target.value)}
                            />
                            Success
                        </label>
                    </div>
                </div>
                <div className={styles.messageInfoBox}>
                    <label>Location:</label>
                    <div className={styles.messageRadioGroup}>
                        <label>
                            <input
                                type="radio"
                                value="/dashboard"
                                checked={location === '/dashboard'}
                                onChange={(e) => setLocation(e.target.value)}
                            />
                            Dashboard
                        </label>
                        <label>
                            <input
                                type="radio"
                                value="/bot"
                                checked={location === '/bot'}
                                onChange={(e) => setLocation(e.target.value)}
                            />
                            Bot
                        </label>
                        <label>
                            <input
                                type="radio"
                                value="/config"
                                checked={location === '/config'}
                                onChange={(e) => setLocation(e.target.value)}
                            />
                            Config
                        </label>
                    </div>
                </div>
            </div>
            <button className={styles.button} onClick={handleSendMessage}>Send Message</button>
            <div className={styles.activeMessages}>
                <h2>Active Messages</h2>
                {activeMessages.map(msg => (
                    <div key={msg.id} className={styles.activeMessage}>
                        <p><strong>Message:</strong> {msg.message}</p>
                        <p><strong>Type:</strong> {msg.type}</p>
                        <p><strong>Location:</strong> {msg.location}</p>
                        <p><strong>Time Sent:</strong> {new Date(msg.timeSend).toLocaleString()}</p>
                        <button className={styles.activeMessageButton} onClick={() => handleRemoveMessage(msg.id)}>Remove</button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default MessagesContent;
