import React, { useState, useEffect, useRef, useContext } from 'react';
import styles from './UpdatesContent.module.css';
import { MessageContext } from '../../../../context/MessageContext';

const UpdatesContent = () => {
    const [modules, setModules] = useState([]);
    const [selectedModule, setSelectedModule] = useState('');
    const [baseVersion, setBaseVersion] = useState({ major: null, minor: null, patch: null });
    const [currentVersion, setCurrentVersion] = useState({ major: null, minor: null, patch: null });
    const [file, setFile] = useState(null);
    const [path, setPath] = useState('/root/Api/routes/bots/modules/');
    const [type, setType] = useState('Core');
    const [searchTerm, setSearchTerm] = useState('');
    const fileInputRef = useRef(null);
    const [useTextArea, setUseTextArea] = useState(false);
    const [textAreaContent, setTextAreaContent] = useState('');
    const [fileExtension, setFileExtension] = useState('.sk');
    const [useChangelog, setUseChangelog] = useState(false);
    const [changelogTitle, setChangelogTitle] = useState('');
    const [changelogDescription, setChangelogDescription] = useState('');
    const { sendMessage } = useContext(MessageContext);

    useEffect(() => {
        const fetchUpdateInfos = async () => {
            const userToken = JSON.parse(localStorage.getItem('userToken')).token;

            try {
                const response = await fetch('https://ayodev.de:2096/admin/getUpdateInfos', {
                    headers: {
                        'Authorization': `Bearer ${userToken}`
                    }
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch update infos');
                }

                const data = await response.json();
                console.log(data);
                const processedModules = data.map(module => ({
                    id: module.id,
                    name: module.name,
                    major: module.major,
                    minor: module.minor,
                    patch: module.patch,
                    releaseDate: module.release_Date,
                    path: module.path,
                    type: module.type
                }));

                setModules(processedModules);
            } catch (error) {
                console.error('Error fetching update infos:', error);
            }
        };

        fetchUpdateInfos();
    }, []);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setFile(e.dataTransfer.files[0]);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const userToken = JSON.parse(localStorage.getItem('userToken')).token;
        sendMessage('Uploading update...', 'info');

        const fileContent = useTextArea ? textAreaContent : await file.text();
        const formData = {
            module: selectedModule,
            major: currentVersion.major,
            minor: currentVersion.minor,
            patch: currentVersion.patch,
            fileContent,
            fileName: useTextArea ? 'textAreaContent.sk' : file.name,
            requestPath: path,
            type,
            fileExtension,
            changelogTitle: useChangelog ? changelogTitle : undefined,
            changelogDescription: useChangelog ? changelogDescription : undefined,
        };

        try {
            const response = await fetch('https://ayodev.de:2096/admin/postUpdate', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${userToken}`,
                    'Content-Type': 'application/json'
                },
                timeout: 100,
                body: JSON.stringify(formData)
            });

            if (!response.ok) {
                sendMessage('Failed to upload update', 'error');
                throw new Error('Failed to upload update');
            }

            const data = await response.json();
            console.log('Update uploaded and distributed successfully:', data);
            sendMessage(`Update send successfully\ncommandSend: ${data.commandSend}\ncommandSendError: ${data.commandSendError}\nfileSend: ${data.fileSend}\nfileSendError: ${data.fileSendError}`, 'success');
        } catch (error) {
            console.error('Error uploading update:', error);
        }
    };

    const handleModuleSelect = (module) => {
        setSelectedModule(module.name);
        setBaseVersion({ major: module.major, minor: module.minor, patch: module.patch });
        setCurrentVersion({ major: module.major, minor: module.minor, patch: module.patch });
        setPath(module.path);
        setType(module.type);
    };

    const handleVersionUpdate = (type) => {
        if (type === 'major') {
            setCurrentVersion({
                major: baseVersion.major + 1,
                minor: 0,
                patch: 0
            });
        } else if (type === 'minor') {
            setCurrentVersion({
                major: baseVersion.major,
                minor: baseVersion.minor + 1,
                patch: 0
            });
        } else if (type === 'patch') {
            setCurrentVersion({
                major: baseVersion.major,
                minor: baseVersion.minor,
                patch: baseVersion.patch + 1
            });
        }
    };

    const handleCreateNew = () => {
        setSelectedModule('');
        setCurrentVersion({ major: 1, minor: 0, patch: 0 });
        setPath('/root/Api/routes/bots/modules/');
        setFile(null);
        setType('Core');
    };

    const filteredModules = modules.filter(module =>
        module.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className={styles.container}>
            <h1>Bot Updater</h1>
            <div className={styles.searchContainer}>
                <input
                    type="text"
                    className={styles.searchBar}
                    placeholder="Search modules..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                <button className={styles.addButton} onClick={handleCreateNew}>+</button>
            </div>
            <div className={styles.moduleList}>
                {filteredModules.map(module => (
                    <div
                        className={styles.moduleItem}
                        key={module.id}
                        onClick={() => handleModuleSelect(module)}
                    >
                        <h3>{module.name}</h3>
                        <p>Version: {module.major}.{module.minor}.{module.patch}</p>
                        <p>Path: {module.path}</p>
                        <p>Type: {module.type}</p>
                    </div>
                ))}
            </div>
            {(selectedModule || currentVersion.major !== null) && (
                <form onSubmit={handleSubmit}>
                    <div className={styles.formRow}>
                        <label>
                            Module:
                            <input
                                type="text"
                                value={selectedModule}
                                onChange={(e) => setSelectedModule(e.target.value)}
                                className={styles.textBox}
                            />
                        </label>
                        <label>
                            Path:
                            <input
                                type="text"
                                value={path}
                                onChange={(e) => setPath(e.target.value)}
                                className={styles.textBox}
                            />
                        </label>
                        <label>
                            Type:
                            <select
                                value={type}
                                onChange={(e) => setType(e.target.value)}
                                className={styles.dropdown}
                            >
                                <option value="Core">Core</option>
                                <option value="Module">Module</option>
                                <option value="Zusatzmodule">Zusatzmodule</option>
                            </select>
                        </label>
                    </div>
                    <div className={styles.formRow}>
                        <label>
                            File Extension:
                            <input
                                type="text"
                                value={fileExtension}
                                onChange={(e) => setFileExtension(e.target.value)}
                                className={styles.textBox}
                            />
                        </label>
                    </div>
                    <div className={styles.versionControls}>
                        <button
                            type="button"
                            className={styles.button}
                            onClick={() => handleVersionUpdate('major')}
                        >
                            Update Major
                        </button>
                        <button
                            type="button"
                            className={styles.button}
                            onClick={() => handleVersionUpdate('minor')}
                        >
                            Update Minor
                        </button>
                        <button
                            type="button"
                            className={styles.button}
                            onClick={() => handleVersionUpdate('patch')}
                        >
                            Update Patch
                        </button>
                    </div>
                    <div className={styles.versionDisplay}>
                        <p>Current Version: {currentVersion.major}.{currentVersion.minor}.{currentVersion.patch}</p>
                    </div>
                    <div className={styles.formRow}>
                        <label className="slider-label">
                            <input
                                type="checkbox"
                                className="slider-input"
                                checked={useTextArea}
                                onChange={(e) => setUseTextArea(e.target.checked)}
                            />
                            <span className="slider"></span>
                            Use Text Area
                        </label>
                    </div>
                    {useTextArea ? (
                        <textarea
                            value={textAreaContent}
                            onChange={(e) => setTextAreaContent(e.target.value)}
                            className={styles.textArea}
                            rows="10"
                        />
                    ) : (
                        <div
                            className={styles.fileUpload}
                            onDrop={handleDrop}
                            onDragOver={handleDragOver}
                            onClick={() => fileInputRef.current.click()}
                        >
                            {file ? (
                                <p>{file.name}</p>
                            ) : (
                                <p>Drop file here or click to upload</p>
                            )}
                            <input type="file" onChange={handleFileChange} className={styles.fileInput} ref={fileInputRef} />
                        </div>
                    )}
                    <div className={styles.formRow}>
                        <label className="slider-label">
                            <input
                                type="checkbox"
                                className="slider-input"
                                checked={useChangelog}
                                onChange={(e) => setUseChangelog(e.target.checked)}
                            />
                            <span className="slider"></span>
                            Use Changelog
                        </label>
                    </div>
                    {useChangelog && (
                        <>
                            <div className={styles.formRow}>
                                <label>
                                    Changelog Title:
                                    <input
                                        type="text"
                                        value={changelogTitle}
                                        onChange={(e) => setChangelogTitle(e.target.value)}
                                        className={styles.textBox}
                                    />
                                </label>
                            </div>
                            <div className={styles.formRow}>
                                <label>
                                    Changelog Description:
                                    <textarea
                                        value={changelogDescription}
                                        onChange={(e) => setChangelogDescription(e.target.value)}
                                        className={styles.textArea}
                                        rows="5"
                                    />
                                </label>
                            </div>
                        </>
                    )}
                    <button type="submit" className={styles.submitButton}>Upload Update</button>
                </form>
            )}
        </div>
    );
};

export default UpdatesContent;
