import React, { useState, useEffect, useRef, useContext } from 'react';
import styles from './CommandContent.module.css';
import { MessageContext } from '../../../../context/MessageContext';

const CommandContent = () => {
    const [modules, setModules] = useState([]);
    const [selectedModule, setSelectedModule] = useState('');
    const [quickCommands, setQuickCommands] = useState([]);
    const [newCommandName, setNewCommandName] = useState('');
    const [newCommand, setNewCommand] = useState('');
    const [textAreaContent, setTextAreaContent] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const { sendMessage } = useContext(MessageContext);

    useEffect(() => {
        const fetchCommandInfos = async () => {
            const userToken = JSON.parse(localStorage.getItem('userToken')).token;

            try {
                const response = await fetch('https://ayodev.de:2096/admin/getCommandInfos', {
                    headers: {
                        'Authorization': `Bearer ${userToken}`
                    }
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch command infos');
                }

                const data = await response.json();
                console.log(data[0]);
                console.log(data[1]);
                setQuickCommands(data[0]);
                setModules(data[1]);
            } catch (error) {
                console.error('Error fetching command infos:', error);
            }
        };

        fetchCommandInfos();
    }, []);

    const handleModuleSelect = (module) => {
        handleCommandExecute(`sk reload ${module.path.replace('/plugins/Skript/scripts/', '')}${module.name}`);
    };

    const handleDeleteCommand = async (commandName) => {
        const userToken = JSON.parse(localStorage.getItem('userToken')).token;

        try {
            const response = await fetch('https://ayodev.de:2096/admin/deleteQuickCommand', {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${userToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ commandName })
            });

            if (!response.ok) {
                throw new Error('Failed to delete quick command');
            }

            sendMessage('Quick command deleted successfully', 'success');
            setQuickCommands(quickCommands.filter(command => command.name !== commandName));
        } catch (error) {
            console.error('Error deleting quick command:', error);
            sendMessage('Failed to delete quick command', 'error');
        }
    };

    const handleNewCommandSubmit = async (e) => {
        e.preventDefault();
        if (!newCommandName || !newCommand) {
            sendMessage('Both command name and command must be filled', 'error');
            return;
        }

        if (quickCommands.some(command => command.name === newCommandName)) {
            sendMessage('Command name already exists', 'error');
            return;
        }

        const userToken = JSON.parse(localStorage.getItem('userToken')).token;

        try {
            const response = await fetch('https://ayodev.de:2096/admin/postNewQuickCommand', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${userToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ commandName: newCommandName, command: newCommand })
            });

            if (!response.ok) {
                throw new Error('Failed to create new quick command');
            }

            const newQuickCommand = await response.json();
            sendMessage('Quick command created successfully', 'success');
            setQuickCommands([...quickCommands, newQuickCommand]);
            setNewCommandName('');
            setNewCommand('');
        } catch (error) {
            console.error('Error creating new quick command:', error);
            sendMessage('Failed to create new quick command', 'error');
        }
    };

    const handleCommandExecute = async (command) => {
        const userToken = JSON.parse(localStorage.getItem('userToken')).token;

        try {
            const response = await fetch('https://ayodev.de:2096/admin/postCommand', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${userToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ command })
            });

            if (!response.ok) {
                throw new Error('Failed to execute command');
            }

            sendMessage('Command executed successfully', 'success');
        } catch (error) {
            console.error('Error executing command:', error);
            sendMessage('Failed to execute command', 'error');
        }
    };

    const handleTextAreaSubmit = async (e) => {
        e.preventDefault();
        handleCommandExecute(textAreaContent);
    };

    const filteredModules = modules.filter(module =>
        module.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className={styles.container}>
            <h1>Command Sender</h1>
            <div className={styles.section}>
                <h2>Fast Reload</h2>
                <input
                    type="text"
                    placeholder="Search Modules"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className={styles.searchBar}
                />
                <div className={styles.moduleList}>
                    {filteredModules.map(module => (
                        <div
                            className={styles.moduleItem}
                            key={module.id}
                            onClick={() => handleModuleSelect(module)}
                        >
                            <h3>{module.name}</h3>
                            <p>Version: {module.major}.{module.minor}.{module.patch}</p>
                        </div>
                    ))}
                </div>
            </div>
            <div className={styles.section}>
                <h2>Quick Commands</h2>
                <div className={styles.quickCommandList}>
                    {quickCommands.map(command => (
                        <div
                            className={styles.quickCommandItem}
                            key={command.id}
                            onClick={() => handleCommandExecute(command.command)}
                        >
                            <h3>{command.name}</h3>
                            <p>{command.command}</p>
                            <button onClick={() => handleDeleteCommand(command.name)}>Delete</button>
                        </div>
                    ))}
                </div>
                <form onSubmit={handleNewCommandSubmit} className={styles.form}>
                    <input
                        type="text"
                        placeholder="Command Name"
                        value={newCommandName}
                        onChange={(e) => setNewCommandName(e.target.value)}
                        className={styles.textBox}
                    />
                    <input
                        type="text"
                        placeholder="Command"
                        value={newCommand}
                        onChange={(e) => setNewCommand(e.target.value)}
                        className={styles.textBox}
                    />
                    <button type="submit" className={styles.button}>Add Command</button>
                </form>
            </div>
            <div className={styles.section}>
                <h2>Textbox Input</h2>
                <form onSubmit={handleTextAreaSubmit} className={styles.form}>
                    <textarea
                        value={textAreaContent}
                        onChange={(e) => setTextAreaContent(e.target.value)}
                        className={styles.textArea}
                        rows="5"
                    />
                    <button type="submit" className={styles.button}>Execute Command</button>
                </form>
            </div>
        </div>
    );
};

export default CommandContent;
