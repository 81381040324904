import React, { useState, useEffect, useRef } from 'react';
import styles from './BotContent.module.css';

const BotContent = () => {
    const [bots, setBots] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedBot, setSelectedBot] = useState(null);
    const [filter, setFilter] = useState('all');
    const popupRef = useRef();

    useEffect(() => {
        const fetchBots = async () => {
            try {
                const response = await fetch('https://ayodev.de:2096/admin/bots', {
                    headers: {
                        'Authorization': `Bearer ${JSON.parse(localStorage.getItem('userToken')).token}`
                    }
                });
                const data = await response.json();
                console.log(data);
                setBots(data);
            } catch (error) {
                console.error('Error fetching bots:', error);
            }
        };

        fetchBots();
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setSelectedBot(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [popupRef]);

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    const filteredBots = bots.filter(bot => {
        const matchesSearchTerm = bot.DC_BOT_NAME.toLowerCase().includes(searchTerm.toLowerCase()) ||
            bot.DC_BOT_ID.toLowerCase().includes(searchTerm.toLowerCase());
        const matchesFilter = filter === 'all' || (filter === 'free' && bot.FREEBOT === 'true') || (filter === 'non-free' && bot.FREEBOT === 'false');
        return matchesSearchTerm && matchesFilter;
    });

    const handleRowClick = (bot) => {
        setSelectedBot(bot);
    };

    const handleClosePopup = () => {
        setSelectedBot(null);
    };

    return (
        <div className={styles.botContent}>
            <div className={styles.filterContainer}>
                <input
                    type="text"
                    placeholder="Search bots..."
                    value={searchTerm}
                    onChange={handleSearch}
                    className={styles.searchInput}
                />
                <select value={filter} onChange={handleFilterChange} className={styles.filterSelect}>
                    <option value="all">All Bots</option>
                    <option value="free">Free Bots</option>
                    <option value="non-free">Non-Free Bots</option>
                </select>
            </div>
            <div className={styles.botContainer}>
                {filteredBots.map(bot => (
                    <div key={bot.ID} className={styles.botCard} onClick={() => handleRowClick(bot)}>
                        <img src={bot.AVATAR_URL} alt={bot.DC_BOT_NAME} className={styles.botAvatar} loading="lazy" />
                        <div className={`${styles.botStatus} ${styles[bot.STATUS.toLowerCase()]}`}></div>
                        <div className={styles.botName}>{bot.DC_BOT_NAME}</div>
                    </div>
                ))}
            </div>
            {selectedBot && (
                <div className={styles.botPopup}>
                    <div className={styles.popupContent} ref={popupRef}>
                        <button className={styles.closeButton} onClick={handleClosePopup}>Close</button>
                        <h2>Bot Details</h2>
                        <p><strong>Bot Name:</strong> {selectedBot.BOTNAME}</p>
                        <p><strong>Bot ID:</strong> {selectedBot.BOTID}</p>
                        <p><strong>Server:</strong> {selectedBot.SERVER}</p>
                        <p><strong>Server ID:</strong> {selectedBot.SERVERID}</p>
                        <p><strong>Invite:</strong> {selectedBot.INVITE}</p>
                        <p><strong>Modules:</strong> {selectedBot.MODULES}</p>
                        <p><strong>Period:</strong> {selectedBot.PERIOD}</p>
                        <p><strong>Start Timestamp:</strong> {selectedBot.START_TIMESTAMP}</p>
                        <p><strong>End Timestamp:</strong> {selectedBot.END_TIMESTAMP}</p>
                        <p><strong>Zentrum:</strong> {selectedBot.ZENTRUM}</p>
                        <p><strong>Permissions:</strong> {selectedBot.PERMISSIONS}</p>
                        <p><strong>Token:</strong> {selectedBot.TOKEN}</p>
                        <p><strong>IP Address:</strong> {selectedBot.IP_ADDRESS}</p>
                        <p><strong>Avatar URL:</strong> {selectedBot.AVATAR_URL}</p>
                        <p><strong>Banner URL:</strong> {selectedBot.BANNER_URL}</p>
                        <p><strong>Activity:</strong> {selectedBot.ACTIVITY}</p>
                        <p><strong>Status:</strong> {selectedBot.STATUS}</p>
                        <p><strong>Zusatz Module:</strong> {selectedBot.ZUSATZ_MODULE}</p>
                        <p><strong>Owners:</strong> {selectedBot.OWNERS}</p>
                        <p><strong>Admin:</strong> {selectedBot.ADMIN}</p>
                        <p><strong>Freebot:</strong> {selectedBot.FREEBOT}</p>
                        <p><strong>Servers:</strong> {selectedBot.SERVERS}</p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default BotContent;
