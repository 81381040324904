import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import styles from './Permissions.module.css';

const Permissions = ({ show, onClose }) => {
    const [owners, setOwners] = useState([]);
    const [admins, setAdmins] = useState([]);
    const [newAdmin, setNewAdmin] = useState('');
    const [showAddAdmin, setShowAddAdmin] = useState(false);
    const inputRef = useRef(null);

    useEffect(() => {
        const botDetails = JSON.parse(localStorage.getItem('botDetails'))[0];
        if (botDetails) {
            setOwners(botDetails.owners.split('|bigcut§!').filter(owner => owner !== 'none'));
            setAdmins(botDetails.admins.split('|bigcut§!').filter(admin => admin !== 'none'));
        }
    }, []);

    useEffect(() => {
        if (showAddAdmin && inputRef.current) {
            inputRef.current.focus();
        }
    }, [showAddAdmin]);

    const handleAddAdmin = async () => {
        if (newAdmin) {
            try {
                const token = JSON.parse(localStorage.getItem('userToken')).token;
                const botToken = JSON.parse(localStorage.getItem('botToken')).botToken;

                const response = await axios.post(
                    'https://ayodev.de:2096/api/checkForOwner',
                    { botToken, newAdmin },
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json'
                        }
                    }
                );

                if (response.data.message === 'User is the owner and command sent') {
                    setAdmins([...admins, newAdmin]);
                    setNewAdmin('');
                    setShowAddAdmin(false);
                } else {
                    alert('You are not the owner');
                }
            } catch (error) {
                console.error('Error adding admin:', error);
                alert('Failed to add admin');
            }
        }
    };

    const handleRemoveAdmin = async (adminToRemove) => {
        try {
            const token = JSON.parse(localStorage.getItem('userToken')).token;
            const botToken = JSON.parse(localStorage.getItem('botToken')).botToken;

            const response = await axios.post(
                'https://ayodev.de:2096/api/removeAdmin',
                { botToken, adminToRemove },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );

            if (response.data.message === 'User is the owner and command sent') {
                setAdmins(admins.filter(admin => admin !== adminToRemove));
            } else {
                alert('You are not the owner');
            }
        } catch (error) {
            console.error('Error removing admin:', error);
            alert('Failed to remove admin');
        }
    };

    const handleBlur = () => {
        setTimeout(() => {
            setShowAddAdmin(false);
        }, 200);
    };

    if (!show) {
        return null;
    }

    return (
        <div className={styles.popupOverlay} onClick={onClose}>
            <div className={styles.permissionPopupContent} onClick={e => e.stopPropagation()}>
                <h2>Permissions</h2>
                <div className={styles.permissionsContainer}>
                    <div className={styles.owners}>
                        <h3>Owners</h3>
                        <ul>
                            {owners.map((owner, index) => (
                                <li key={index}>{owner}</li>
                            ))}
                        </ul>
                    </div>
                    <div className={styles.admins}>
                        <h3>Admins</h3>
                        <ul>
                            {admins.filter(admin => admin !== '<none>').map((admin, index) => (
                                <li key={index}>
                                    {admin}
                                    <button className={styles.removeButton} onClick={() => handleRemoveAdmin(admin)}>Remove</button>
                                </li>
                            ))}
                        </ul>
                        <div className={styles.addAdminContainer}>
                            {!showAddAdmin && (
                                <button className={styles.addAdminButton} onClick={() => setShowAddAdmin(true)}>+</button>
                            )}
                            {showAddAdmin && (
                                <div className={styles.addAdmin}>
                                    <input
                                        type="text"
                                        value={newAdmin}
                                        onChange={(e) => setNewAdmin(e.target.value)}
                                        placeholder="Enter Discord ID"
                                        ref={inputRef}
                                        onBlur={handleBlur}
                                    />
                                    <button onClick={handleAddAdmin}>Add Admin</button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <button className={styles.closeButton} onClick={onClose}>X</button>
            </div>
        </div>
    );
};

export default Permissions;