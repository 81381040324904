import React, { useState, useRef, useEffect } from 'react';
import styles from './ConfigList.module.css';

const ConfigList = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [showSearch, setShowSearch] = useState(false);
    const [configs, setConfigs] = useState(JSON.parse(localStorage.getItem('configs')));
    const searchInputRef = useRef(null);

    const handleConfigChange = async (configName, isEnabled, event) => {
        event.stopPropagation();
        const token = JSON.parse(localStorage.getItem('userToken')).token;
        const botToken = JSON.parse(localStorage.getItem('botToken')).botToken;
        const command = `moduleUpdate ${isEnabled ? 'enable' : 'disable'} ${configName.replace(/ /g, '_')}`;

        if (!token || !botToken) {
            console.error('Token or botToken not found in local storage');
            return;
        }
    
        try {
            const response = await fetch('https://ayodev.de:2096/api/sendCommand', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ botToken, command })
            });
    
            if (!response.ok) {
                throw new Error('Failed to send command');
            }

            // Update local state
            setConfigs(prevConfigs => 
                prevConfigs.map(config => 
                    config.name === configName ? { ...config, enabled: isEnabled } : config
                )
            );
        } catch (error) {
            console.error('Error sending command:', error);
        }
    };

    const handleSwitchClick = (event) => {
        event.stopPropagation();
    };

    const configClick = (configName) => {
        if (configName) {
            window.location.href = `https://ayodev.de/dashboard/bot/config/${configName.replace(/ /g, '_')}`;
        }
    };

    const toggleSearch = () => {
        setShowSearch(!showSearch);
        if (!showSearch) {
            setTimeout(() => {
                searchInputRef.current.focus();
            }, 0);
        } else {
            setSearchTerm('');
        }
    };

    const filteredConfigs = configs.filter(config => 
        config.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className={styles.dashboardBotConfigs}>
            <div className={styles.dashboardBotConfigsTop}>
                <h1 className={styles.dashboardBotConfigHeader}>Bot Configs</h1>
                {showSearch && (
                    <input 
                        type="text" 
                        className={styles.dashboardBotConfigSearchBox} 
                        placeholder="Search configs..." 
                        value={searchTerm} 
                        onChange={(e) => setSearchTerm(e.target.value)} 
                        ref={searchInputRef}
                    />
                )}
                <div className={styles.dashboardBotConfigSearch} onClick={toggleSearch}>
                    <i className="fas fa-search">
                        <svg className={styles.icon} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                            <circle cx="10" cy="10" r="7" stroke="white" fill="none" strokeWidth="2"></circle>
                            <line x1="14" y1="14" x2="20" y2="20" stroke="white" strokeWidth="2"></line>
                        </svg>
                    </i>
                </div>
            </div>
            <div className={styles.dashboardBotConfigCards}>
                {configs.filter(config => 
                    config.name.toLowerCase().includes(searchTerm.toLowerCase())
                ).map((config, index) => (
                    <div key={index} className={styles.dashboardBotConfigCard} onClick={() => configClick(config.name)}>
                        <h2>{config.name}</h2>

                        <label className="slider-label" onClick={handleSwitchClick}>
                            <input
                                type="checkbox"
                                className="slider-input"
                                checked={config.enabled}
                                onChange={(e) => handleConfigChange(config.name, e.target.checked, e)} 
                            />
                            <span className="slider"></span>
                        </label>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ConfigList;
