import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './AdminDashboard.module.css';
import AdminSidebar from './AdminSidebar';
import DashboardContent from './Content/DashboardContent';
import MessagesContent from './Content/MessagesContent';
import UsersContent from './Content/UsersContent';
import BotContent from './Content/BotContent';
import PowerContent from './Content/botControll/PowerContent';
import CommandContent from './Content/botControll/CommandContent';
import UpdatesContent from './Content/botControll/UpdatesContent';
import TokensContent from './Content/botControll/TokensContent';

const AdminDashboard = () => {
    const [loading, setLoading] = useState(true);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [activePage, setActivePage] = useState('dashboard');
    const navigate = useNavigate();

    useEffect(() => {
        const checkRole = async () => {
            try {
                const response = await fetch('https://ayodev.de:2096/admin/test', {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(localStorage.getItem('userToken')).token}`,
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    if (data.role === 'admin' || data.role === 'owner') {
                        setIsAdmin(true);
                    } else {
                        navigate('/dashboard');
                    }
                } else {
                    navigate('/login');
                }
            } catch (error) {
                console.error('Error checking role:', error);
                navigate('/login');
            } finally {
                setLoading(false);
            }
        };

        checkRole();
    }, [navigate]);

    useEffect(() => {
        const savedPage = sessionStorage.getItem('activePage');
        if (savedPage) {
            setActivePage(savedPage);
        }
    }, []);

    useEffect(() => {
        sessionStorage.setItem('activePage', activePage);
    }, [activePage]);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!isAdmin) {
        return null;
    }

    return (
        <div className={styles.adminDashboard}>
            <AdminSidebar 
                isOpen={isSidebarOpen} 
                toggleSidebar={toggleSidebar} 
                setActivePage={(page) => {
                    setActivePage(page);
                    sessionStorage.setItem('activePage', page);
                }} 
            />
            <div className={`${styles.adminContent} ${isSidebarOpen ? '' : styles.sidebarClosed}`}>
                {activePage === 'dashboard' && <DashboardContent />}
                {activePage === 'statistics' && <h1>Statistics Content</h1>}
                {activePage === 'messages' && <MessagesContent />}
                {activePage === 'bots' && <BotContent />}
                {activePage === 'settings' && <h1>Settings Content</h1>}
                {activePage === 'users' && <UsersContent />}
                {activePage === 'power' && <PowerContent />}
                {activePage === 'command' && <CommandContent />}
                {activePage === 'updates' && <UpdatesContent />}
                {activePage === 'tokens' && <TokensContent />}
            </div>
        </div>
    );
};

export default AdminDashboard;
