import React, { useContext } from 'react';
import styles from './PowerContent.module.css';
import { MessageContext } from '../../../../context/MessageContext';

const PowerContent = () => {
    const { sendMessage } = useContext(MessageContext);

    const handlePowerAction = async (action) => {
        const userToken = JSON.parse(localStorage.getItem('userToken')).token;

        try {
            const response = await fetch('https://ayodev.de:2096/admin/postPowerAction', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${userToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ action })
            });

            if (!response.ok) {
                throw new Error('Failed to perform power action');
            }

            sendMessage(`Power action ${action} executed successfully`, 'success');
        } catch (error) {
            console.error(`Error performing power action ${action}:`, error);
            sendMessage(`Failed to perform power action ${action}`, 'error');
        }
    };

    return (
        <div className={styles.container}>
            <h1>Power Actions</h1>
            <div className={styles.buttonGroup}>
                <button onClick={() => handlePowerAction('start')} className={styles.button}>Start</button>
                <button onClick={() => handlePowerAction('restart')} className={styles.button}>Restart</button>
                <button onClick={() => handlePowerAction('stop')} className={styles.button}>Shutdown</button>
            </div>
        </div>
    );
};

export default PowerContent;
