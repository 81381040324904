import React, { useState, useEffect } from 'react';
import styles from './ServerDisplay.module.css';

const ServerDisplay = () => {
    const [guilds, setGuilds] = useState([]);
    const [currentGuild, setCurrentGuild] = useState(null);
    const [showGuildList, setShowGuildList] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(true); 

    useEffect(() => {
        const fetchData = async () => {
            const userToken = JSON.parse(localStorage.getItem('userToken')).token;

            try {
                const response = await fetch('https://ayodev.de:2096/api/guilds', {
                    method: 'POST',
                    headers: { 'Authorization': `Bearer ${userToken}` }
                });

                if (response.ok) {
                    const data = await response.json();
                    setGuilds(data.guilds);
                }
            } catch (error) {
                console.error('Failed to fetch data from API');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        console.log(guilds)
        if (guilds.length > 0) {
            const currentBot = JSON.parse(localStorage.getItem('currentBot'));
            var serverid = currentBot ? currentBot.guild_id : null;

            if (serverid) {
                var foundGuild = null;
                guilds.forEach(guild => {
                    if (guild.id === serverid) {
                        foundGuild = guild;
                    }
                });
                console.log(foundGuild);
                if (foundGuild) {
                    setCurrentGuild(foundGuild);
                }
            }
        }
    }, [guilds]);

    const handleSwitchGuild = (guild) => {
        const currentBot = JSON.parse(localStorage.getItem('currentBot'));
        const updatedBot = { ...currentBot, guild_id: guild.id };

        localStorage.setItem('currentBot', JSON.stringify(updatedBot));
        setCurrentGuild(guild);

        const inviteUrl = `https://discord.com/oauth2/authorize?client_id=${updatedBot.dc_bot_id}&permissions=8&integration_type=0&scope=bot`;
        window.open(inviteUrl, '_blank');

        setShowGuildList(false);
    };

    const getGuildIcon = (guild) => {
        if (guild.icon) {
            return `https://cdn.discordapp.com/icons/${guild.id}/${guild.icon}.png`;
        }
        return 'https://avatarfiles.alphacoders.com/367/367017.png';
    };

    const filteredGuilds = guilds
        .filter(guild => guild.permissions & 0x20)
        .filter(guild => guild.name.toLowerCase().includes(searchTerm.toLowerCase()))
        .sort((a, b) => a.name.localeCompare(b.name));

    return (
        <div className={styles.serverDisplay}>
            {loading ? (
                <div className="spinner-container">
                    <div className="spinner"></div>
                </div>
            ) : (
                <>
                    {currentGuild ? (
                        <div>
                            <h2>Aktueller Server</h2>
                            <div className={styles.guildDisplay}>
                                <img
                                    src={getGuildIcon(currentGuild)}
                                    className={styles.guildIcon}
                                    alt={`${currentGuild.name} Icon`}
                                />
                                <h3>{currentGuild.name}</h3>
                            </div>
                        </div>
                    ) : (
                        <p>Du teilst dir keinen Server mit dem Bot</p>
                    )}
                    <button onClick={() => setShowGuildList(true)}>Server wechseln</button>
                </>
            )}

            {showGuildList && (
                <div className={styles.popupOverlay} onClick={() => setShowGuildList(false)}>
                    <div className={styles.popupContent} onClick={e => e.stopPropagation()}>
                        <h2>Server auswählen</h2>
                        <input
                            type="text"
                            className={styles.searchBar}
                            placeholder="Server suchen..."
                            value={searchTerm}
                            onChange={e => setSearchTerm(e.target.value)}
                        />
                        <div className={styles.guildList}>
                            {filteredGuilds.map(guild => (
                                <div className={styles.guildItem} key={guild.id}>
                                    <img
                                        src={getGuildIcon(guild)}
                                        className={styles.guildIcon}
                                        alt={`${guild.name} Icon`}
                                    />
                                    <span>{guild.name}</span>
                                    <button onClick={() => handleSwitchGuild(guild)}>Wechseln</button>
                                </div>
                            ))}
                        </div>
                        <p className={styles.disclaimer}>
                            <span className={styles.disclaimerIcon}>⚠️</span> Hinweis: Der Server wird nur gezeigt wenn du die Berechtigung hast, den Bot einzuladen.
                        </p>
                        <button className={styles.closeButton} onClick={() => setShowGuildList(false)}>X</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ServerDisplay;